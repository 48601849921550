import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Typography, Button } from "@material-ui/core";
import BookingModal from "../BookingModal/BookingModal";
import SyllabusForm from "../SyllabusForm";
import SyllabusRequest from "../SyllabusRequest";
import { Link } from "gatsby";

// import { Link, StaticQuery } from "gatsby";
const Bildungsgutschein = ({ title, blurbs, classes }) => {
  const theme = useTheme();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography
        component="h2"
        variant="h3"
        align="center"
        gutterBottom
        style={{ marginBottom: theme.spacing(6) }}
      >
        {title}
      </Typography>
      {/* {blurbs.map((item, index) => (
        <React.Fragment key={index}>
          <Typography
            component="h3"
            variant="h4"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {item.title}
          </Typography>
          <Typography
            component="h5"
            variant="h6"
            gutterBottom
            style={{ textAlign: "justify" }}
          >
            {item.text}
          </Typography>
        </React.Fragment>
      ))} */}

      <React.Fragment>
        <Typography
          component="h3"
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          You could be eligible to take our courses for free
        </Typography>
        <Typography
          component="h5"
          variant="h6"
          gutterBottom
          style={{ textAlign: "justify", marginBottom: theme.spacing(4) }}
        >
          Code Academy Berlin is a certified education institute and can
          therefore accept the Bildungsgutschein (education voucher) from the
          Agentur für Arbeit / Jobcenter (Employment agency). The voucher can be
          given to you for any necessary training you need, for you to enter the
          workforce, change your career, or secure a job for future tasks. The
          voucher covers the full fees, costs for transportation and child care.
          Also note, your unemployment money (ALGI) will be extended for 50% of
          the time you spend with us.
        </Typography>
        <Typography
          component="h3"
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          Where do I get a Bildungsgutschein?
        </Typography>
        <Typography
          component="h5"
          variant="h6"
          gutterBottom
          style={{ textAlign: "justify", marginBottom: theme.spacing(1) }}
        >
          The Bildungsgutschein can be issued by the Agentur für Arbeit /
          Jobcenter. In order to get a mandatory consultancy talk, you need to
          register and declare unemployment, that you are open to work or at
          risk of losing your job. The registration can be done online{" "}
          <a
            className={classes.link}
            href="https://www.arbeitsagentur.de/arbeitslos-arbeit-finden/arbeitslosengeld"
            // target="_blank"
            rel="noopener"
          >
            here
          </a>
          , you can call the hotline 0800 4 555500 in German, or go in person to
          the nearest Agency in your district for consultancy. As a former agent
          of the Federal Employment agency in Berlin, our Career Advisor Jost can help you prepare the necessary resources,
          documents and tips for the talk with your agent.
        </Typography>

        {/* <BookingModal link /> */}
        <Button
          variant="text"
          component={Link}
          color="primary"
          to="/advisors"
        >Talk to our advisors</Button>
        <Typography
          component="h3"
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          Find our courses on Kursnet
        </Typography>
        <Typography
          component="h5"
          variant="h6"
          gutterBottom
          style={{ textAlign: "justify", marginBottom: theme.spacing(1) }}
        >
          Kursnet is the online platform that references courses eligible to the Bildungsgutschein. You can find Code Academy Berlin's courses using this link{" "}
          <a
            className={classes.link}
            href="https://web.arbeitsagentur.de/weiterbildungssuche/suche?ort=Berlin_13.4056_52.5178&uk=ort&seite=0&dauer=1,2,3,4,5,6&sw=Web%2520Development,Data%2520Analytics&uz=1&uf=101&anbieter=253683"
            // target="_blank"
            rel="noopener"
          >
            Kursnet
          </a>


        </Typography>
        <Typography
          component="h3"
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }}
        >
          I want to upskill or re-skill but not quit my job, any tips?
        </Typography>
        <Typography
          component="h5"
          variant="h6"
          gutterBottom
          style={{ textAlign: "justify", marginBottom: theme.spacing(4) }}
        >
          Yes! We have some great news for you. Germany passed a law which
          allows you to get mandatory training in digital skill sets and
          supports you in getting it. It is called the{" "}
          <a
            className={classes.link}
            href="https://www.arbeitsagentur.de/m/weiterbildung-qualifizierungsoffensive/"
            target="_blank"
            rel="noreferrer"
          >
            Qualifizierungschancengesetz
          </a>
          . If you want to learn coding and your employer supports the idea, we
          have just the right course for you. Our part-time evening classes can
          be covered by the Bildungsgutschein for upskill classes. Get in touch
          with us to learn more. The best part - depending on the size of your
          company, up to 100% of the fees can be covered PLUS up to 75% of your
          salary for the time you are in our classes. Sounds good? Get in touch
          with us to learn how we can make it happen for you.
        </Typography>
        <Typography
          component="h3"
          align="center"
          variant="h4"
          style={{ fontWeight: "bold", marginBottom: theme.spacing(2) }}
        >
          You can request our Bildungsgutschein handbook by with our course syllabus
        </Typography>

        <SyllabusRequest isMobile={true} />
      </React.Fragment>
    </Container>
  );
};

export default withStyles(styles)(Bildungsgutschein);
